<template>
    <div class="w-100">
        <div class="w-100" v-if="refundModel !== null">
            <el-form :model="refundModel" :rules="rules" ref="refundModelForm">
                <el-form-item prop="userId" class="m-0">
                    <div>
                        <div class="mb-1 mt-2" style="line-height: normal;"><strong>Step 1: Enter username</strong></div>                                
                        <div>
                            <el-input :disabled="!allowedChooseProvider"
                                placeholder="Input username.."
                                v-model="refundModel.userId"
                                clearable>
                            </el-input>
                        </div>
                    </div>
                </el-form-item>
                <div class="mt-4">
                    <div class="mb-1" style="line-height: normal;"><strong>Step 2: Select a Provider</strong></div>
                    <div>
                        <el-form-item prop="provider" class="m-0">
                            <el-radio-group v-model="refundModel.provider" size="medium" class="w-100"
                                            :disabled="!allowedChooseProvider"
                                            fill="#92d9ff">
                                <el-radio-button :label="itemProvider.id" :key="itemProvider.id"
                                                    v-for="itemProvider in providerSelection">
                                    <img :src="`/img/${itemProvider.id}.svg`" style="height: 1rem;">
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="mb-1">
                        <el-form-item prop="transactionCode" class="m-0">
                            <el-input :disabled="!allowedChooseProvider"
                                placeholder="Input transaction code.."
                                v-model="refundModel.transactionCode"
                                clearable>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-input
                            placeholder="Input references transaction ID (optional)"
                            v-model="refundModel.transactionRefId"
                            clearable>
                        </el-input>
                    </div>
                </div>
                <el-form-item prop="amount" class="m-0">
                    <div class="mt-1 mt-2" >
                        <div class="mb-1" style="line-height: normal;"><strong>Step 3: Enter amount refund</strong></div>                                
                        <div>
                            <el-input-number v-model="refundModel.amount" 
                                                :disabled="!allowedChooseProvider"
                                                :precision="2" :step="1" 
                                                :max="99999999999" 
                                                class="w-100"></el-input-number>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="reason" class="m-0">
                    <div class="mt-4">
                        <div class="mb-1 mt-2" style="line-height: normal;"><strong>Step 4: Enter reason for this transaction</strong></div>                                
                        <div>
                                <el-input
                                    placeholder="Input reason"
                                    v-model="refundModel.reason"
                                    clearable>
                                </el-input>
                        </div>
                    </div>
                </el-form-item>

                <div class="mt-4 text-right text-n">
                    <div>                    
                        <el-button type="danger" @click="closeAddRefundTransaction" round class="bg-gradient-danger"><i class="el-icon-close"></i> Give up</el-button>
                        <el-button type="primary" round @click="submitForm('refundModelForm')"
                                   class="bg-gradient-blue">
                            <i class="el-icon-circle-check"></i> Do it !
                        </el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import commonAct from '@/commonActionHandle.js';
    import baseComponent from "@/scripts/baseComponent";
    import gpuHubTransactionApi from '@/api/gpuHub/gpuHubTransaction';
    export default {
        extends: baseComponent,
        props: {
            refundData: {
                type: Object,
                default: null
            },
        },
        watch: {
            refundData(newVal) {                
                this.$set(this, "allowedChooseProvider", this.refundData !== null ? false : true);
                this.$set(this, "refundModel", {
                    isPassUsername: this.refundData !== null ? false : true,
                    userId: this.refundData !== null ? this.refundData.userId : "",
                    transactionId: this.refundData !== null ? this.refundData.transactionId : "",
                    transactionCode: this.refundData !== null ? this.refundData.transactionCode : "",
                    provider: this.refundData !== null ? this.refundData.provider : this.providerSelection[0].id,
                    amount: this.refundData !== null ? this.refundData.amount : 0,
                });
            }
        },
        data() {
            return {
                isLoading: false,
                allowedChooseProvider: false,
                refundModel: {
                    provider: 'BANKING',
                    amount: 0,
                    reason: null,
                },
                providerSelection: [
                    { id: 'BANKING', text: 'BANKING' },
                    { id: 'CARD', text: 'CARD' },
                    { id: 'PAYPAL', text: 'PAYPAL' },
                    { id: 'MOMO', text: 'MOMO' },
                ],
                rules: {
                    amount: [
                        { required: true, message: 'Please enter an amount', trigger: 'change' },
                        {
                            validator(rule, value, callback) {
                                if (Number.isInteger(Number(value)) && Number(value) > 0) {
                                    callback()
                                } else {
                                    callback(new Error('Please enter amount value greater than 0'))
                                }
                            },
                            trigger: 'change'
                        }
                    ],
                    reason: [
                        {
                            required: true,
                            message: "Reason must not be blank",
                            trigger: "change"
                        }
                    ],
                    userId: [
                        {
                            required: true,
                            message: "Username must not be blank",
                            trigger: "change"
                        }
                    ]
                }
            };
        },
        mounted() {
            this.$set(this, "allowedChooseProvider", this.refundData !== null ? false : true);
            this.$set(this, "refundModel", {
                isPassUsername: this.refundData !== null ? false : true,
                userId: this.refundData !== null ? this.refundData.userId : "",
                transactionId: this.refundData !== null ? this.refundData.transactionId : "",
                transactionCode: this.refundData !== null ? this.refundData.transactionCode : "",
                provider: this.refundData !== null ? this.refundData.provider : this.providerSelection[0].id,
                amount: this.refundData !== null ? this.refundData.amount : 0,
            });
        },
        methods: {
            closeAddRefundTransaction() {
                this.$emit("closeAddRefundTransaction");
            },
            submitForm(formName) {                
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        commonAct.showConfirm("Add refund transaction ?", () => {
                            this.showLoading();
                            let request = Object.assign(
                                {
                                    deviceInformation: JSON.stringify(this.getInformationClient()),
                                },
                                this.refundModel
                            );
                            gpuHubTransactionApi.addRefundTransaction(request).then(response => {
                                if (response.data.result === 0) {                                    
                                    console.log("addRefundTransaction", response.data.data);
                                    this.closeAddRefundTransaction();
                                    this.showSuccessToast(
                                        "Add refund transaction has been success"
                                    );
                                } else {
                                    commonAct.showError(response.data.message);
                                }
                                this.hideLoading();
                            }).catch(error => {
                                console.error(error);
                                commonAct.showError(error);
                                this.hideLoading();
                            });
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>
